<template>
  <div style="direction: rtl">
    <Loader />
    <b-row class="m-0 p-0 justify-content-center">
      <b-col md="6">
        <div class="p-5">
          <router-view/>
        </div>
      </b-col>
      <b-col md="6" class="m-0 p-0" :style="{ 'background-size': 'cover', 'background-image': 'url(' + coverImage + ')'  }">
        <div class="gradient-green">
          <img alt="camel" :src="require('@/assets/images/logo.svg')"/>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import Loader from '../components/core/loader/Loader'
export default {
  data () {
    return {
      coverImage: require('@/assets/images/ibbil/login-image.png')
    }
  },
  components: {
    Loader
  }
}
</script>
<style>
@import url("../assets/css/custom.css");
@import url("../assets/css/PriceSlider.css");
.unread {
  left: -16px;
  top: 5px;
  font-size: 9px;
}
.mark {
  background-color: #e3e5fc !important;
}
.allNotifications {
  overflow: auto;
  height: 30rem;
}
.h-100vh{
  height: 100vh;
}
.gradient-green {
  background: transparent linear-gradient(9deg, #2B5842 0%, #35A36E6C 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
</style>
